import '@babel/polyfill';
import Hangman from './hangman-class';
import { getPuzzleAsync, getPuzzleFetch, getCountry, getLocation } from './requests';

//Primitive value: string, number, boolean, null, undefined

//Object: myObject -> Object.prototype -> null
//Array: myArray -> Array.prototype -> Object.prototype -> null
//Function: myFunc -> Function.prototype -> Object.prototype -> null
//String: myString -> String.prototype -> Object.prototype -> null
//Number: myNumber -> Number.prototype -> Object.prototype -> null
//Boolean: myBoolean -> Boolean.prototype -> Object.prototype -> null

//HTTP
//Request - What do we want to do
//Response - What was actually done

let body = document.querySelector('body');
let getPuzzleElement = document.querySelector('#puzzle');
let gameResultElement = document.querySelector('#guesses');
let resetBtn = document.querySelector('#button');

let game1;

window.addEventListener('keypress', (e) => {
  let guess = String.fromCharCode(e.charCode);
  game1.makeGuess(guess);
  render();
});

const render = () => {
  getPuzzleElement.innerHTML = '';
  gameResultElement.textContent = game1.statusMessage;

  getPuzzleElement.innerHTML = game1.puzzle
    .split('')
    .map((elem) => `<span>${elem}</span>`)
    .join('');
};

const startGame = async () => {
  const puzzle = await getPuzzleAsync('2');
  game1 = new Hangman(puzzle, 5);
  render();
};

document.querySelector('#reset').addEventListener('click', startGame);

startGame();

//Making Asynchronous HTTP Request

getPuzzleAsync('2')
  .then((puzzle) => {
    console.log(`getPuzzleAsync: ${puzzle}`);
  })
  .catch((error) => {
    console.log(error);
  });

getPuzzleFetch('2')
  .then((puzzle) => {
    console.log(`getPuzzleFetch: ${puzzle}`);
  })
  .catch((error) => {
    console.log(error);
  });

const countryCode = 'TW';

getCountry(countryCode)
  .then((countryName) => {
    console.log(`Country name: ${countryName}`);
  })
  .catch((error) => {
    console.log(`${error}`);
  });

// getCountry(countryCode).then(
//   ({ countryName, country }) => {
//     console.log(`Country name: ${countryName}`);
//   },
//   (error) => {
//     console.log(`Error: ${error}`);
//   }
// );

//Using Fetch example
// fetch(`https:/puzzle.mead.io/puzzle`, {})
//   .then((response) => {
//     if (response.status === 200) {
//       return response.json(); //returning a Promise object
//     } else {
//       throw Error('unable to get puzzle');
//     }
//   })
//   .then((data) => {
//     console.log(`Getting Puzzle from location2:  ${data.puzzle}`);
//   })
//   .catch((error) => {
//     console.log(error);
//   });

getLocation()
  .then(({ city, region, country }) => {
    console.log(`City: ${city}, Region: ${region}, Country: ${country}`);
    return getCountry(country);
  })
  .then((countryName) => {
    console.log(`Country name: ${countryName}`);
  })
  .catch((error) => {
    console.log(error);
  });

// getCurrentCountry()
//   .then((countryName) => {
//     console.log(`getCurrentCountry: ${countryName}`);
//   })
//   .catch((error) => {
//     console.log(`getCurrentCountry: ${error}`);
//   });
