const getPuzzleAsync = async (wordCount) => {
  const response = await fetch(`//puzzle.mead.io/puzzle?wordCount=${wordCount}`, {});
  if (response.status === 200) {
    const data = await response.json();
    return data.puzzle;
  } else {
    throw new Error('An error has taken place');
  }
};

const getPuzzleFetch = (wordCount) => {
  return fetch(`//puzzle.mead.io/puzzle?wordCount=${wordCount}`, {})
    .then((response) => {
      if (response.status === 200) {
        return response.json(); //return an Promise object
      } else {
        throw new Error('An error has taken place');
      }
    })
    .then((data) => {
      return data.puzzle;
    });
};
//Using Promise and XMLHttpRequest to fetch and parse JSON from an URL
// new Promise((resolve, reject) => {
//   const request = new XMLHttpRequest();

//   request.addEventListener('readystatechange', (e) => {
//     if (e.target.readyState === 4 && e.target.status === 200) {
//       const data = JSON.parse(e.target.responseText);
//       resolve(data.puzzle);
//     } else if (e.target.readyState === 4) {
//       reject('An error has taken place');
//     }
//   });

//   request.open('GET', `https:/puzzle.mead.io/puzzle?wordCount=${wordCount}`);
//   //request.open('GET', `https:/puzzle.mead.io/slow-puzzle?wordCount=${wordCount}`); //try this to experience Asynchronous
//   request.send();
// });

//using async-await to fetch from an URL
const getCountry = async (countryCode) => {
  const response = await fetch('//restcountries.eu/rest/v2/all', {});
  if (response.status === 200) {
    const countries = await response.json();
    return countries.find((country) => country.alpha2Code === countryCode).name;
  } else {
    throw new Error('Unable to get country Name');
  }
};

//using fetch to fetch from an URL
// const getCountry = (countryCode) => {
//   return fetch('https://restcountries.eu/rest/v2/all', {})
//     .then((response) => {
//       if (response.status === 200) {
//         return response.json(); //return Promise object
//       } else {
//         throw new Error('Unable to get country Name');
//       }
//     })
//     .then((countries) => {
//       return countries.find((country) => country.alpha2Code === countryCode).name;
//     });
// };

//Using Promise and XMLHttpRequest to fetch and parse JSON from an URL
// new Promise((resolve, reject) => {
//   const request2 = new XMLHttpRequest();

//   request2.addEventListener('readystatechange', (e) => {
//     if (e.target.readyState === 4 && e.target.status === 200) {
//       const countries = JSON.parse(e.target.responseText);
//       //Method 1 to get Country Name
//       const country = countries.find((country) => country.alpha2Code === countryCode);
//       resolve({ countryName: country.name, country: country });
//       //Method 2 to get Country Name
//       //const [{ name }] = countries.filter((country) => country.alpha2Code === countryCode);
//       //console.log(`Method 2: ${name}`);
//     } else if (e.target.readyState === 4) {
//       reject('Unable to get country Name');
//     }
//   });

//   request2.open('GET', 'https://restcountries.eu/rest/v2/all');
//   request2.send();
// });

//using async-await to fetch from an URL
const getLocation = async () => {
  const response = await fetch('//ipinfo.io/json?token=a83c58ed4d76f9', {});
  if (response.status === 200) {
    const location = await response.json();
    return { city: location.city, region: location.region, country: location.country };
  } else {
    throw new Error('Unable to fetch Location');
  }
};

//using fetch to fetch from an URL
// const getLocation = () => {
//   return fetch('https://ipinfo.io/json?token=a83c58ed4d76f9', {})
//     .then((response) => {
//       if (response.status === 200) {
//         return response.json(); //return promise
//       } else {
//         throw new Error('Unable to fetch Location');
//       }
//     })
//     .then((location) => {
//       return { city: location.city, region: location.region, country: location.country };
//     });
// };

// const getCurrentCountry = async () => {
//   const { country } = await getLocation();
//   const currentCountryName = await getCountry(country);
//   return currentCountryName;
// };

export { getPuzzleAsync, getPuzzleFetch, getCountry, getLocation };
