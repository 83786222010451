class Hangman {
  constructor(word, remainingGuesses) {
    this.word = word.toLowerCase().split('');
    this.remainingGuesses = remainingGuesses;
    this.guessedLetters = [];
    this.status = 'playing'; //playing, finished, failed
  }
  // getPuzzle() {
  //   let puzzle = '';
  //   this.word.forEach(
  //     (letter) => (puzzle += this.guessedLetters.includes(letter) ? letter : letter === ' ' ? ' ' : '*')
  //   );
  //   return puzzle;
  // }
  get puzzle() {
    let puzzle = '';
    this.word.forEach(
      (letter) => (puzzle += this.guessedLetters.includes(letter) ? letter : letter === ' ' ? ' ' : '*')
    );
    return puzzle;
  }
  makeGuess(userGuess) {
    if (this.status !== 'playing') {
      return;
    }
    const isUnique = !this.guessedLetters.includes(userGuess);
    const isBadGuess = !this.word.includes(userGuess);
    if (isUnique) this.guessedLetters.push(userGuess);
    if (isBadGuess) this.remainingGuesses--;
    this.calcStatus();
  }
  calcStatus() {
    let isMatched = this.word.every((letter) => this.guessedLetters.includes(letter) || letter === ' ');
    if (isMatched && this.remainingGuesses > 0) {
      this.status = 'finished';
    } else if (this.remainingGuesses <= 0) {
      this.status = 'failed';
    } else {
      this.status = 'playing';
    }
  }
  // displayStatusMessage() {
  //   if (this.status === 'playing') {
  //     return `Guesses left: ${this.remainingGuesses}`;
  //   } else if (this.status === 'finished') {
  //     return `Great work! You guessed the word.`;
  //   } else {
  //     return `Nice try! The word was "${this.word.join('')}"`;
  //   }
  // }
  get statusMessage() {
    if (this.status === 'playing') {
      return `Guesses left: ${this.remainingGuesses}`;
    } else if (this.status === 'finished') {
      return `Great work! You guessed the word.`;
    } else {
      return `Nice try! The word was "${this.word.join('')}"`;
    }
  }
}

export { Hangman as default };
